<template>
  <div v-if="isMobile">
    <AppBarMobile>
      <VRow
        justify="center"
        align="center"
      >
        <VCol
          offset="2"
          class="text-center"
        >
          <h1
            data-test="levels-view-h1"
            class="tt-text-title-1 tt-light-mono-4--text"
          >
            {{ $tc('names.levels',1) }}
          </h1>
        </VCol>
        <VCol cols="2">
          <VBtn
            icon
            color="tt-secondary"
            @click="showWidgetPanel"
          >
            <VIcon>fas fa-info</VIcon>
          </VBtn>
        </VCol>
      </VRow>
    </AppBarMobile>
    <VContainer class="py-0">
      <div class="my-6">
        <VRow
          v-for="(level, index) in onboardingTrack"
          :key="level.id"
          class="my-0"
        >
          <VCol
            :offset="offsetGenerate(index)"
            :cols="$vuetify.breakpoint.xsOnly? 12 : $options.LEVEL_COLS"
            class="py-0"
          >
            <TrackLine
              v-if="(index % 2 === 0) && index"
              :current-state="level.state"
              :prev-state="prevOnboardingTrack(index).state"
              :state-text="getStateText(level)"
              before
              data-test="levels-track-line-right"
            />
            <!-- TODO: роутинг по клику и проверка открыт или нет -->
            <TrackCard
              :data-test="`levels-track-card-${index}`"
              :level="level"
            />
            <TrackLine
              v-if="(index % 2 === 0) && index!== onboardingTrack.length - 1"
              :current-state="level.state"
              :next-state="nextOnboardingTrack(index).state"
              :state-text="getStateText(nextOnboardingTrack(index))"
              data-test="levels-track-line-left"
            />
          </VCol>
        </VRow>
      </div>
    </VContainer>
    <TrackAsideRight />
  </div>
  <div v-else>
    <VContainer class="pa-0">
      <VRow>
        <VCol cols="12">
          <h1
            data-test="levels-view-h1"
            class="tt-text-headline-1"
          >
            {{ $tc('names.levels',1) }}
          </h1>
        </VCol>
      </VRow>
      <div class="my-6">
        <VRow
          v-for="(level, index) in onboardingTrack"
          :key="level.id"
          class="my-0"
        >
          <VCol
            :offset="offsetGenerate(index)"
            :cols="$vuetify.breakpoint.xsOnly? 12 : $options.LEVEL_COLS"
            class="py-0"
          >
            <TrackLine
              v-if="(index % 2 === 0) && index"
              :current-state="level.state"
              :prev-state="prevOnboardingTrack(index).state"
              :state-text="getStateText(level)"
              before
              data-test="levels-track-line-right"
            />
            <!-- TODO: роутинг по клику и проверка открыт или нет -->
            <TrackCard
              :data-test="`levels-track-card-${index}`"
              :level="level"
            />
            <TrackLine
              v-if="(index % 2 === 0) && index!== onboardingTrack.length - 1"
              :current-state="level.state"
              :next-state="nextOnboardingTrack(index).state"
              :state-text="getStateText(nextOnboardingTrack(index))"
              data-test="levels-track-line-left"
            />
          </VCol>
        </VRow>
      </div>
    </VContainer>
  </div>
</template>

<script>
import TrackLine from '@/components/track/TrackLine.vue';
import TrackCard from '@/components/track/TrackCard.vue';
import { LEVEL_COLS } from '@/components/track/constans';
import { mapGetters } from 'vuex';
import AppBarMobile from '@/components/app/AppBarMobile.vue';
import TrackAsideRight from '@/components/track/TrackAsideRight.vue';

export default {
  name: 'LevelsView',
  components: {
    TrackAsideRight,
    AppBarMobile,
    TrackCard,
    TrackLine,
  },
  LEVEL_COLS,
  computed: {
    ...mapGetters('levels', ['onboardingTrack']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    showWidgetPanel() {
      this.$root.$emit('click:show-widget-panel');
    },
    nextOnboardingTrack(i) {
      return this.onboardingTrack[i + 1];
    },
    prevOnboardingTrack(i) {
      return this.onboardingTrack[i - 1];
    },
    getStateText(onboardingTrack) {
      return onboardingTrack?.details?.stateText;
    },
    offsetGenerate(index) {
      if (this.$vuetify.breakpoint.xsOnly) return 0;
      return index % 2 !== 0 ? (12 - this.$options.LEVEL_COLS) : 0;
    },
  },
};
</script>
